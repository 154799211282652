<template>
    <div class="screensaver">
        <div class="page-top">
            <div class="logo" @click="goto('/')">
                <div class="picbox">
                    <div class="pic" style="background-image: url(/img/logo.png)"></div>
                </div>
            </div>
        </div>
        <div class="mainbox">
            <div class="left-con">

                <div class="titlebox">
                    <div class="atitle">心理热线</div>
                    <div class="btitle">如果您遇到任何心理问题需要咨询或得到建议，欢迎拨打免费心理咨询热线</div>
                    <div class="txt">
                        <a class="t1">
                            <div class="picbox">
                                <div class="pic" style="background-image: url(/img/screensaver_phone.png)"></div>
                            </div>
                            <span>021-962525（市级心理热线）</span>
                        </a>
                        <div class="t2" @click="openMap(base.mapLink)">
                            <div class="picbox">
                                <div class="pic" style="background-image: url(/img/screensaver_location.png)"></div>
                            </div>
                            <span>上海市普陀区志丹路211号</span>
                        </div>
                    </div>
                </div>
                
                <div class="more" @click="goto('/contact')">
                    <span>联系我们</span>
                    <div class="picbox">
                        <div class="pic" style="background-image: url(/img/more.png)"></div>
                    </div>
                </div>
            </div>
            <div class="right-con">
                <div class="titlebox">
                    <div class="atitle">悦心亭</div>
                    <div class="btitle">悦己 悦人 悦心</div>
                    <div class="introduce">普陀悦心亭是上海市普陀区精神卫生中心发起建设的覆盖全区的可以实现线上测评、心理科普、音乐放松、心理热线咨询的智能一体化社区心理健康测评与辅导平台。</div>
                </div>
                <div class="btns">
                    <button class="btn" @click="goto('/about')">关于悦心亭</button>
                    <button class="btn" @click="goto('/index')">进入主页</button>
                </div>
            </div>
            <!-- <iframe src="base.mapLink" frameborder="0" width="100%" height="600px"></iframe> -->
        </div>
        <el-dialog
            title="导航"
            v-model="dialogVisible"
            width="80%"
            height="100%"
            :before-close="handleClose"
            >
            <iframe id="reportFrame" frameborder="0" width="100%" height="600px"></iframe>
        </el-dialog>
    </div>
</template>

<script>
import { getBase, getWebrtcParams } from "@/js/request";
export default {
    name: 'Screensaver',
    components: {

    },
    data() {
        return {
            base: {},
            dialogVisible: true,
        }
    },
    methods: {
        goto(adress, cate) {
            this.$router.push({path: adress, query: cate});
        },
        openMap(mapUrl) {
            this.dialogVisible = true;
            document.getElementById("reportFrame").src = mapUrl;
        }
    },
    mounted() {
        console.log("window.location.href=", window.location.href);
        console.log("webrtcId0", this.$route.query.webrtcId);
        console.log("webrtcIndex0", this.$route.query.index);
        if(this.$route.query.webrtcId != null && this.$route.query.webrtcId != undefined){
            window.localStorage.setItem('webrtcId', this.$route.query.webrtcId); 
        }
        if(this.$route.query.index != null && this.$route.query.index !=undefined){
            window.localStorage.setItem('webrtcIndex', this.$route.query.index); 
        }
        const webrtcId = window.localStorage.getItem('webrtcId');
        let webrtcIndex = window.localStorage.getItem('webrtcIndex');
        if(webrtcId != null && webrtcId != undefined && webrtcId != "" &&
            webrtcIndex != null && webrtcIndex != undefined && webrtcIndex != ""){
            console.log("webrtcIndex2", webrtcIndex);
            webrtcIndex = webrtcIndex.split("?")[0];
            console.log("webrtcIndex3", webrtcIndex);
            
            window.localStorage.setItem('currentWebrtcId', webrtcId);
        }
        this.dialogVisible = false;
        this.$root.hideRight();
        getBase().then(res => {
            this.base = res.data;
        });
    }
}
</script>

<style lang="scss">
.screensaver {
    width: 1400px;
    margin: 0 auto;
    padding: 60px 0;
    .page-top {
        @include sys-flex(space-between, row);
        .logo {            
            @include sys-picbox(438px, 80px);
            display: flex;
            align-items: center;
            cursor: pointer;
        }
    }
    .mainbox {
        width: 1400px;
        height: 474px;
        position: fixed;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        display: flex;
        .left-con, .right-con {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
        }
        .left-con {
            @include sys-boxsize(560px, 100%, 20px);
            background: #fff url(../assets/img/screensaver_bg.png) no-repeat 262px 236px/278px 208px;
            box-shadow: 0px 0px 50px 0px rgba(63,101,224,0.05);
            flex: none;
            margin-right: 100px;
            padding: 60px;
            box-sizing: border-box;
            .titlebox {
                line-height: 30px;
                .atitle {
                    @include sys-text-hide(1);
                    @include sys-font(28px, #000, bold);
                    margin-bottom: 20px;
                }
                .btitle {
                    @include sys-text-hide(3);
                    @include sys-font(20px, #333);
                    margin-bottom: 30px;
                }
                .txt {
                    @include sys-picbox(18px, 20px);
                    .picbox {
                        margin-right: 20px;
                        flex: none;
                    }
                    .t1, .t2 {
                        display: flex;
                        align-items: center;
                        margin-bottom: 10px;
                        letter-spacing: 1px;
                        span {
                            @include sys-text-hide(1);
                            @include sys-font(20px, $acolor);
                        }
                    }
                }
            }
            .more {
                @include sys-picbox(14px, 10px);
                display: flex;
                align-items: center;
                cursor: pointer;
                .picbox {
                    margin-left: 10px;
                    flex: none;
                }
                span {
                    @include sys-text-hide(1);
                    @include sys-font(20px, #000);
                }
            }
        }
        .right-con {
            flex: 1;
            height: 100%;
            .titlebox {
                line-height: 70px;
                .atitle {
                    @include sys-text-hide(1);
                    @include sys-font(68px, #000, bold);
                    margin-bottom: 6px;
                }
                .btitle {
                    @include sys-text-hide(1);
                    @include sys-font(48px, #000);
                    margin-bottom: 50px;
                }
                .introduce {
                    @include sys-text-hide(3);
                    @include sys-font(24px, #000);
                    line-height: 40px;
                }
            }
            .btns {
                display: flex;
                .btn {
                    @include sys-radius-btn(180px, 60px);
                    font-size: 20px;
                    box-sizing: border-box;
                    &:first-child {
                        border: 2px solid $acolor;
                        color: $acolor;
                        background: #fff;
                        margin-right: 20px;
                    }
                }
            }
        }
    }
}
</style>